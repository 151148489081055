@import url("https://fonts.googleapis.com/css2?family=Skranji:wght@700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
	margin: 0;
	padding: 0;
	overflow-x: hidden;
}

body {
	background-color: #000300;
}
